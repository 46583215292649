import { gql } from "graphql-request";
import {
  ClearReservedNumbersForPurchaseResult,
  ClearReservedTollfreeNumbersForPurchaseResult,
  ListReservedNumbersForPurchaseResult,
  OrderReservedNumbersResult,
  ReserveNumbersForPurchaseResult,
  ReserveTollfreeNumbersForPurchaseResult,
  SearchNumbersForPurchaseResult,
  UseCaseType,
} from "~/types";
import { graphqlClient } from "./graphqlClient";

// **Example Flow**

// - 1 - searchPhoneNumbersForPurchase - User searches for numbers, uses filters to get the numbers they want/need
// - 2 - reserveNumbers - User reserves numbers they want
// - 3 - releaseReservedNumbers - User removes 2 numbers (e.g. they changed their mind)
// - 4 - orderNumbers - User purchases the numbers

type searchPhoneNumbersForPurchaseArgs = {
  contains: string;
  state: string;
  quantity: number;
  rateCenter: string;
  consecutive: boolean;
};

export async function searchPhoneNumbersForPurchase({
  contains,
  state,
  quantity,
  rateCenter,
  consecutive,
}: searchPhoneNumbersForPurchaseArgs) {
  const query = gql`
    query searchPhoneNumbersQuery(
      $contains: String
      $quantity: Int
      $state: String
      $rateCenter: String
      $consecutive: Boolean
    ) {
      numbers(
        contains: $contains
        quantity: $quantity
        state: $state
        rateCenter: $rateCenter
        consecutive: $consecutive
      ) {
        numbers {
          city
          telephoneNumber
          province
          countryCode
          rateCenter
          rateCenterTier
        }
      }
    }
  `;

  const data = {
    contains,
    state,
    quantity,
    rateCenter,
    consecutive,
  };

  try {
    const response: SearchNumbersForPurchaseResult = await graphqlClient(
      query,
      data
    );
    // numbers is both the query and the response object, so we need to access numbers 2 times
    if (response.numbers.numbers) {
      return response.numbers.numbers;
    } else {
      throw response;
    }
  } catch (errorResponse) {
    throw errorResponse;
  }
}

export async function listReservedPhoneNumbersForPurchase() {
  const query = gql`
    query listReservedPhoneNumbersQuery {
      reservedList {
        numbers {
          city
          telephoneNumber
          province
          countryCode
          rateCenter
          rateCenterTier
        }
      }
    }
  `;

  // no params
  const data = {};

  try {
    const response: ListReservedNumbersForPurchaseResult = await graphqlClient(
      query,
      data
    );
    if (response.reservedList.numbers) {
      return response.reservedList.numbers;
    } else {
      throw response;
    }
  } catch (errorResponse) {
    throw errorResponse;
  }
}

export async function listReservedTollfreePhoneNumbersForPurchase() {
  const query = gql`
    query listReservedPhoneNumbersQuery {
      tollfreeReservedList
    }
  `;

  // no params
  const data = {};

  try {
    const response: { tollfreeReservedList: string[] } = await graphqlClient(
      query,
      data
    );

    if (response.tollfreeReservedList) {
      return response.tollfreeReservedList;
    } else {
      throw response;
    }
  } catch (errorResponse) {
    throw errorResponse;
  }
}

type ReserveNumbersForPurchaseArgs = {
  numbers: string[];
};

export async function reserveNumbersForPurchase({
  numbers,
}: ReserveNumbersForPurchaseArgs) {
  const mutation = gql`
    mutation reserveNumbersMutation($numbers: [String!]!) {
      numbersReserve(numbers: $numbers) {
        reserved
        reservedTotal
        requested
        requestedTotal
        excluded
        excludedTotal
      }
    }
  `;

  const data = {
    numbers,
  };

  try {
    const response: ReserveNumbersForPurchaseResult = await graphqlClient(
      mutation,
      data
    );
    // numbers is both the query and the response object, so we need to access numbers 2 times
    if (response.numbersReserve) {
      return response.numbersReserve;
    } else {
      throw response;
    }
  } catch (errorResponse) {
    throw errorResponse;
  }
}

export async function reserveTollfreeNumbersForPurchase({
  numbers,
}: ReserveNumbersForPurchaseArgs) {
  const mutation = gql`
    mutation reserveTollfreeNumbersMutation($numbers: [String!]!) {
      tollfreeNumbersReserve(numbers: $numbers) {
        reserved
      }
    }
  `;

  const data = {
    numbers,
  };

  try {
    const response: ReserveTollfreeNumbersForPurchaseResult =
      await graphqlClient(mutation, data);
    // numbers is both the query and the response object, so we need to access numbers 2 times
    if (response.tollfreeNumbersReserve) {
      return response.tollfreeNumbersReserve;
    } else {
      throw response;
    }
  } catch (errorResponse) {
    throw errorResponse;
  }
}

export async function clearReservedNumbersForPurchase({
  numbers,
}: ReserveNumbersForPurchaseArgs) {
  const mutation = gql`
    mutation releaseReservedMutation($numbers: [String!]!) {
      releaseReserved(numbers: $numbers) {
        excluded
        excludedCount
        releasedCount
        totalCount
      }
    }
  `;

  const data = {
    numbers,
  };

  try {
    const response: ClearReservedNumbersForPurchaseResult = await graphqlClient(
      mutation,
      data
    );
    // numbers is both the query and the response object, so we need to access numbers 2 times
    if (response.releaseReserved) {
      return response.releaseReserved;
    } else {
      throw response;
    }
  } catch (errorResponse) {
    throw errorResponse;
  }
}

export async function clearReservedTollfreeNumbersForPurchase({
  numbers,
}: ReserveNumbersForPurchaseArgs) {
  const mutation = gql`
    mutation releaseReservedTollfreeMutation($numbers: [String!]!) {
      releaseReservedTollfree(numbers: $numbers) {
        totalCount
        releasedCount
        excludedCount
        excluded
      }
    }
  `;

  const data = {
    numbers,
  };

  try {
    const response: ClearReservedTollfreeNumbersForPurchaseResult =
      await graphqlClient(mutation, data);
    // numbers is both the query and the response object, so we need to access numbers 2 times
    if (response.releaseReservedTollfree) {
      return response.releaseReservedTollfree;
    } else {
      throw response;
    }
  } catch (errorResponse) {
    throw errorResponse;
  }
}

export async function orderReservedNumbers(
  smsEnable: boolean,
  useCaseType?: UseCaseType
) {
  const mutation = gql`
    mutation orderReservedNumbersMutation(
      $smsEnable: Boolean
      $useCaseType: UseCaseType
    ) {
      numbersOrder(smsEnable: $smsEnable, useCaseType: $useCaseType) {
        details {
          ccId
          dids {
            did
            account
            orderid
            rateCenter
            carrier
            state
            tier
          }
          failed
          numbers
          internalId
          reserved {
            rateCenter
            province
            city
          }
          updates {
            id
            smsStatus
          }
          enablements {
            did
            provider
            status
            carrier
          }
        }
        items {
          tn
          tnNote
          trunkGroup
        }
        orderId
      }
    }
  `;

  // Mutation will order the reserved numbers,
  // the only param is whether the numbers
  // are going to be SMS enabled or not.
  const data = {
    smsEnable,
    useCaseType: useCaseType
      ? useCaseType.toLocaleUpperCase()
      : "account_default",
  };

  try {
    const response: OrderReservedNumbersResult = await graphqlClient(
      mutation,
      data
    );
    // numbers is both the query and the response object, so we need to access numbers 2 times
    if (response.numbersOrder) {
      return response.numbersOrder;
    } else {
      throw response;
    }
  } catch (errorResponse) {
    throw errorResponse;
  }
}

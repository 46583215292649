import { Link, useLocation } from "react-router-dom";
import { Layout } from "../../components/Layout";
import { Button } from "~/components/Button";

type LocationState = {
  purchasedNumbers: {
    number: string;
    state: string;
    rateCenter: string;
    smsEnable: string;
  }[];
  failedToPurchaseNumbers: string[];
  type: "toll-free" | "long-code";
};

export function PurchaseNumbersComplete() {
  const location = useLocation();

  const purchaseResponse = location.state as LocationState;

  const purchasedNumbers = purchaseResponse?.purchasedNumbers || [];
  const failedToPurchaseNumbers =
    purchaseResponse?.failedToPurchaseNumbers || [];
  const numbersType = purchaseResponse?.type || "";

  return (
    <Layout className="flex flex-col justify-between min-h-[95vh]">
      <div>
        <h2 className="text-2xl lg:text-3xl font-bold mt-2">
          Number purchase complete
        </h2>

        <div className="py-2 align-middle inline-block min-w-full relative mt-8">
          <div className="shadow overflow-x-auto overflow-y-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  Number
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  State
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  Rate center
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  SMS enabled
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  Status
                </th>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {purchasedNumbers.map((number) => (
                  <tr key={number.number}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {number.number}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {number.state}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {number.rateCenter}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {number.smsEnable ? "Yes" : "No"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <div className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Ordered
                      </div>
                    </td>
                  </tr>
                ))}

                {failedToPurchaseNumbers.map((number) => (
                  <tr key={number}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {number}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      N/A
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      N/A
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <div className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Failed to order
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {numbersType === "toll-free" ? (
          <div>
            It usually takes about 10 minutes for voice service activation.{" "}
            <br />
            <br />
            Messaging will not work until you submit Verified sender form for
            this number.
          </div>
        ) : null}

        <div className="flex gap-4 mt-8">
          <Link to="/purchase-phone-numbers">
            <Button
              light
              className="bg-green-500 hover:bg-green-400 text-white h-min-[40px] w-[223px]"
            >
              Get more numbers
            </Button>
          </Link>

          <Link to="/phone-numbers">
            <Button
              light
              className="bg-amber-400 hover:bg-amber-300  text-white h-min-[40px] w-[260px]"
            >
              View numbers inventory
            </Button>
          </Link>

          {numbersType === "toll-free" ? (
            <Link
              to={`/tf-verification-form?numbers=${purchasedNumbers
                .map((n) => n.number)
                .join(",")}`}
            >
              <Button
                light
                className="bg-blue-500 hover:bg-blue-400  text-white h-min-[40px] w-[260px]"
              >
                Fill in VSF
              </Button>
            </Link>
          ) : null}
        </div>
      </div>
    </Layout>
  );
}

export function isCanadianPrefix(containsString: string): boolean {
  let prefix: string;

  if (containsString.startsWith("1")) {
    prefix = containsString.slice(1, 4);
  } else {
    prefix = containsString.slice(0, 3);
  }

  // @ts-ignore
  return prefixes[prefix] || false;
}

// Canadian prefixes without the "1"
const prefixes = {
  // Manitoba
  "204": true,
  // Ontario
  "226": true,
  // British Columbia
  "236": true,
  // Ontario
  "249": true,
  // British Columbia
  "250": true,
  // Alberta
  "257": true,
  // Ontario
  "263": true,
  // Ontario
  "289": true,
  // Saskatchewan
  "306": true,
  // Ontario
  "343": true,
  // Quebec
  "354": true,
  // Ontario
  "365": true,
  // Quebec
  "367": true,
  // Ontario
  "368": true,
  // Ontario
  "382": true,
  // Newfoundland and Labrador
  "387": true,
  // Alberta
  "403": true,
  // Ontario
  "416": true,
  // Quebec
  "418": true,
  // Ontario
  "428": true,
  // Quebec
  "431": true,
  // Ontario
  "437": true,
  // Quebec
  "438": true,
  // Quebec
  "450": true,
  // Ontario
  "460": true,
  // Quebec
  "468": true,
  // Ontario
  "474": true,
  // New Brunswick
  "506": true,
  // Quebec
  "514": true,
  // Ontario
  "519": true,
  // Quebec
  "537": true,
  // Ontario
  "548": true,
  // Ontario
  "568": true,
  // Quebec
  "579": true,
  // Quebec
  "581": true,
  // Newfoundland and Labrador
  "584": true,
  // Alberta
  "587": true,
  // British Columbia
  "604": true,
  // Ontario
  "613": true,
  // Quebec
  "639": true,
  // Ontario
  "647": true,
  // Ontario
  "672": true,
  // Ontario
  "683": true,
  // Ontario
  "705": true,
  // Newfoundland and Labrador
  "709": true,
  // Ontario
  "742": true,
  // Ontario
  "753": true,
  // British Columbia
  "778": true,
  // Alberta
  "780": true,
  // Nova Scotia
  "782": true,
  // Ontario
  "807": true,
  // Quebec
  "819": true,
  // Ontario
  "825": true,
  // Ontario
  "851": true,
  // Ontario
  "867": true,
  // Prince Edward Island
  "873": true,
  // Newfoundland and Labrador
  "879": true,
  // Nova Scotia
  "902": true,
  // Ontario
  "905": true,
  // Ontario
  "942": true,
};
